import React from 'react'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'

const Commitment = props => (
  <Section aside title='AluPriser.dk' {...props}>
    <Card variant='paper'>
      <Text variant='p'>
        Der er alt for mange steder man kan få dårlig informationer om dæk og fælge. Det er også produkter der er svære at forstå og kræver hjælp til at finde det rigtige. Det vil jeg gerne forbedre. Derfor har jeg lavet siden her.

        Siden er delt op i to dele, dels information om produkter og tilbehørs produkter som ventiler, hjulkapsler, centreringsringe osv. Og dels en produktsøger hvor du kan finde de produkter som passer på din bil.
      </Text>
    </Card>
  </Section>
)

export default Commitment
