import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
import ContactInfo from '@widgets/ContactInfo'
import Commitment from '@widgets/Commitment'
import { IconButton, Text } from 'theme-ui'
import { FaEnvelope, FaLocationArrow, FaPhone, FaSignature, FaBuilding } from 'react-icons/fa'
import useSiteMetadata from '@helpers-blog/useSiteMetadata'


const PageContact = props => {
  const { phone, address, email } = useSiteMetadata()

  return (
    <Layout {...props}>
      <Seo title='Kontakt' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header="Kontakt os"
            subheader='Kontakt os hvis du har spørgsmål.'
          />

          <Text>
            <IconButton variant='simple' role='presentation'>
              <FaSignature />
            </IconButton>
            AluPriser.dk
          </Text>
          <Text>
            <IconButton variant='simple' role='presentation'>
              <FaBuilding />
            </IconButton>
            Banke Group ApS - DK-33147910
          </Text>

          <Text>
            <IconButton variant='simple' role='presentation'>
              <FaLocationArrow />
            </IconButton>
            {address}
          </Text>

          {/*<Text>*/}
          {/*  <IconButton variant='simple' role='presentation'>*/}
          {/*    <FaPhone />*/}
          {/*  </IconButton>*/}
          {/*  {phone}*/}
          {/*</Text>*/}
          <Text>
            <IconButton variant='simple' role='presentation'>
              <FaEnvelope />
            </IconButton>
            {email}
          </Text>
          <Divider />
          <ContactForm />
        </Main>
        <Sidebar>
          <Commitment />
          <Divider />
          <ContactInfo />
        </Sidebar>
      </Stack>
    </Layout>
  )
}

export default PageContact
